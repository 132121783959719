<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              title="Customer Feedbacks"
              url="/pages/feedbacks/customer-feedbacks/manage"
              :data-source="dataSource"
              :total-count="totalCount"
              @on-delete="(v) => onDelete(v)"
              @on-update-cancel="(v) => loadProducts()"
              @on-row-click="(v) => onViewDetail(v)"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
            >
              <template #columns>
                <DxColumn
                  :data-field="item.field"
                  :caption="item.caption"
                  v-for="(item, index) in dataColumns"
                  :key="index"
                />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import feedbackGeneralApi from '@api/feedback_generals';
import { queryParameters } from '@/schema';

export default {
  name: 'CustomerFeedbackPage',
  components: {},
  data: () => ({
    modalShow: false,
    dataSource: [],
    dataColumns: [
      { caption: 'Reference No.', field: 'refnum' },
      { caption: 'Feedback Status', field: 'feedback_status' },
      { caption: 'Subject', field: 'feedback_subject' },
      { caption: 'Name', field: 'feedback_name' },
      { caption: 'Priority', field: 'priority' },
    ],
    totalCount: 0,
    page: 0,
    size: 0,
  }),
  watch: {
    page(v) {
      this.loadServiceRequest();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadServiceRequest();
      }
    },
  },
  mounted() {
    this.loadGeneralFeedback();
  },
  methods: {
    loadGeneralFeedback() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      feedbackGeneralApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            this.dataSource = data;

            this.totalCount = meta.page.total;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onViewDetail(event) {
      const url = `/pages/feedbacks/customer-feedbacks/view?id=${event.key}`;
      this.$router.push(url);
    },
    onDelete(event) {
      feedbackGeneralApi
        .delete(event)
        .then(() => {
          this.loadGeneralFeedback();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
  },
};
</script>

<style></style>
